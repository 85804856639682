import React from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInputGroup,
  MDBInput,
  MDBRipple,
  MDBRow,
  // MDBTypography,
} from "mdb-react-ui-kit";

import './styles.css';

export function Home() {
  return (
    <div>
      <header>
        <div
          className='p-5 text-center bg-image'
          style={{ backgroundImage: "url('https://images.pexels.com/photos/7319294/pexels-photo-7319294.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')", height: '250px' }}
        >
          <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
            <div className='d-flex justify-content-center align-items-center h-100'>
              <div className='text-white'>
                <h1 className='mb-3'>Make Your Choice</h1>
                <h4 className='mb-3'>Find the best product for yourself</h4>
              </div>
            </div>
          </div>
        </div>
      </header>
      <MDBContainer fluid>
        <MDBRow className="justify-content-center mt-4 mb-0">
          <MDBCol md="12" xl="10">
            <MDBInputGroup>
              <MDBInput label='Search' />
              <MDBBtn rippleColor='dark'>
                <MDBIcon icon='search' />
              </MDBBtn>
            </MDBInputGroup>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer fluid>
        <MDBRow className="justify-content-center mb-0">
          <MDBCol md="12" xl="10">
            <MDBCard className="shadow-0 border rounded-3 mt-5 mb-3">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol md="12" lg="3" className="mb-4 mb-lg-0">
                    <MDBRipple
                      rippleColor="light"
                      rippleTag="div"
                      className="bg-image rounded hover-zoom hover-overlay"
                    >
                      <MDBCardImage
                        src="https://m.media-amazon.com/images/I/612kg3rGyYL._AC_SX679_.jpg"
                        fluid
                        className="w-100"
                        alt="product_image"
                      />
                      {/* <a href="https://www.amazon.com/dp/B07ZPKR714" target="blank">
                        <div
                          className="mask"
                          style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                        ></div>
                      </a> */}
                    </MDBRipple>
                  </MDBCol>
                  <MDBCol md="12" lg="9">
                    <h5>Apple iPhone 11, 64GB, Black - Unlocked (Renewed)</h5>
                    <div className="d-flex flex-row">
                      <div className="text-warning mb-1 me-2">
                        <MDBIcon fas icon="star" />
                        <MDBIcon fas icon="star" />
                        <MDBIcon fas icon="star" />
                        <MDBIcon fas icon="star-half-alt" />
                        <MDBIcon far icon="star" />
                      </div>
                      <span>38,643</span>
                    </div>
                    <hr className="hr" />
                    <div>
                      <div className="d-flex align-items-start mb-3">
                        <MDBCol><b>Brand</b></MDBCol>
                        <MDBCol>Apple</MDBCol>
                      </div>
                      <div className="d-flex align-items-start mb-3">
                        <MDBCol><b>Model Name</b></MDBCol>
                        <MDBCol>iPhone 11</MDBCol>
                      </div>
                      <div className="d-flex align-items-start mb-3">
                        <MDBCol><b>Operating System</b></MDBCol>
                        <MDBCol>iOS</MDBCol>
                      </div>
                      <div className="d-flex align-items-start mb-3">
                        <MDBCol><b>Memory Storage Capacity</b></MDBCol>
                        <MDBCol>64 GB</MDBCol>
                      </div>
                      <div className="d-flex align-items-start mb-3">
                        <MDBCol><b>Color</b></MDBCol>
                        <MDBCol>Black</MDBCol>
                      </div>
                      <div className="d-flex align-items-start mb-3">
                        <MDBCol><b>Screen Size</b></MDBCol>
                        <MDBCol>6.1 Inches</MDBCol>
                      </div>
                    </div>
                    {/* <div className="mt-1 mb-2 text-muted small">
                      <span>Color: Black</span>
                      <span className="text-primary"> • </span>
                      <span>Size: 128GB</span>
                      <span className="text-primary"> • </span>
                      <span>
                        Style: Fully Unlocked
                        <br />
                      </span>
                    </div> */}
                    {/* <div className="mb-2 text-muted small">
                      <span>Unique design</span>
                      <span className="text-primary"> • </span>
                      <span>For men</span>
                      <span className="text-primary"> • </span>
                      <span>
                        Casual
                        <br />
                      </span>
                    </div> */}
                    {/* <p className="mb-4 mb-md-0">
                      Description
                    </p> */}
                    {/* <div className="d-flex flex-row align-items-center mb-3">
                      <h4 className="mb-1 me-1">$719.95</h4>
                      <span className="text-danger">
                        <s>$849.99</s>
                      </span>
                    </div> */}
                    {/* <h6 className="text-success">Free shipping</h6> */}
                    {/* <div className="d-flex flex-row">
                      <MDBBtn
                        color="primary"
                        rippleColor="dark"
                        className="flex-fill ms-1"
                        href='https://www.amazon.com/dp/B07ZPKR714'
                      >
                        Learn more
                      </MDBBtn>
                      <MDBBtn color="danger" className="flex-fill ms-2" href='https://www.amazon.com/dp/B07ZPKR714'>
                        Buy now
                      </MDBBtn>
                    </div> */}
                    {/* <div className="d-flex flex-row">
                      <MDBBtn color="primary" size="lg" className="flex-fill ms-1">
                        Details
                      </MDBBtn>
                      <MDBBtn outline color="primary" size="lg" className="flex-fill ms-2">
                        Add to wish list
                      </MDBBtn>
                    </div> */}
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        {/* <MDBContainer
          fluid
          className="py-4 mb-4"
          style={{ backgroundColor: "#f3f2f2", color: "#000" }}
        >
          <MDBRow className="text-center">
            <MDBCol md="4" className="mb-4 mb-md-0">
              <MDBCard>
                <MDBCardBody className="py-4 mt-2">
                  <h5 className="font-weight-bold">A Strong Choice!</h5>
                  <h6 className="font-weight-bold my-3">
                    A Strong Choice!
                  </h6>
                  <MDBTypography
                    listUnStyled
                    className="d-flex justify-content-center"
                  >
                    <li>
                      <MDBIcon fas icon="star" size="sm" color="info" />
                    </li>
                    <li>
                      <MDBIcon fas icon="star" size="sm" color="info" />
                    </li>
                    <li>
                      <MDBIcon fas icon="star" size="sm" color="info" />
                    </li>
                    <li>
                      <MDBIcon fas icon="star" size="sm" color="info" />
                    </li>
                    <li>
                      <MDBIcon fas icon="star" size="sm" color="info" />
                    </li>
                  </MDBTypography>
                  <p className="mb-2">
                    <MDBIcon fas icon="quote-left" className="pe-2" />
                    Probably the best purchase I could have made for my sons during this pandemic. Very nice console. Easy to operate. A must have!
                  </p>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol md="4" className="mb-4 mb-md-0">
              <MDBCard>
                <MDBCardBody className="py-4 mt-2">
                  <h5 className="font-weight-bold">Great purchase</h5>
                  <h6 className="font-weight-bold my-3">
                    Great purchase
                  </h6>
                  <MDBTypography
                    listUnStyled
                    className="d-flex justify-content-center"
                  >
                    <li>
                      <MDBIcon fas icon="star" size="sm" color="info" />
                    </li>
                    <li>
                      <MDBIcon fas icon="star" size="sm" color="info" />
                    </li>
                    <li>
                      <MDBIcon fas icon="star" size="sm" color="info" />
                    </li>
                    <li>
                      <MDBIcon fas icon="star" size="sm" color="info" />
                    </li>
                    <li>
                      <MDBIcon far icon="star" size="sm" color="info" />
                    </li>
                  </MDBTypography>
                  <p className="mb-2">
                    <MDBIcon fas icon="quote-left" className="pe-2" />
                    Bought this Xbox a long time ago and it’s still working flawlessly. No complaints at all!
                  </p>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol md="4" className="mb-4 mb-md-0">
              <MDBCard>
                <MDBCardBody className="py-4 mt-2">
                  <h5 className="font-weight-bold">Xbox Console Stopped Working</h5>
                  <h6 className="font-weight-bold my-3">Xbox Console Stopped Working</h6>
                  <MDBTypography
                    listUnStyled
                    className="d-flex justify-content-center"
                  >
                    <li>
                      <MDBIcon fas icon="star" size="sm" color="info" />
                    </li>
                  </MDBTypography>
                  <p className="mb-2">
                    <MDBIcon fas icon="quote-left" className="pe-2" />
                    Xbox will not turn on- used intermittently for last 8 months and now it won't work
                  </p>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer> */}
      </MDBContainer>
    </div>
  );
}
